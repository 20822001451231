export default {
    data(){
        return{
            sounds:{
                soundList:[
                    {
                        id:0,
                        title: this.$t('options.sounds.enter'),
                        soundName:'LogON',
                        ico:'fa fa-sign-in',
                        color:'#000',
                        value:50,
                    },
                    {
                        id:0,
                        title: this.$t('options.sounds.exit'),
                        soundName:'LogOFF',
                        ico:'fa fa-sign-out',
                        color:'#000',
                        value:50,
                    },
                    {
                        id:0,
                        title: this.$t('options.sounds.error'),
                        soundName:'ERROR',
                        ico:'fa fa-exclamation-circle',
                        color:'#ff0000',
                        value:50,
                    },
                    {
                        id:0,
                        title: this.$t('options.sounds.confirm'),
                        soundName:'CONFIRM',
                        ico:'fa fa-question-circle',
                        color:'#4285f4',
                        value:50,
                    },
                    {
                        id:0,
                        title: this.$t('options.sounds.alarm'),
                        soundName:'WARNING',
                        ico:'fa fa-warning',
                        color:'#C60',
                        value:50,
                    },
                    {
                        id:0,
                        title: this.$t('options.sounds.inform'),
                        soundName:'INFO',
                        ico:'fa fa-info-circle',
                        color:'#7DB6DB',
                        value:50,
                    },
                    {
                        id:0,
                        title: this.$t('options.sounds.event'),
                        soundName:'ALARM_CLOCK',
                        ico:'fa fa-bell',
                        color:'#000',
                        value:50,
                    },
                    {
                        id:0,
                        title: this.$t('options.sounds.switch'),
                        soundName:'BUTTON_SWITCH',
                        ico:'fa fa-toggle-on',
                        color:'#008000',
                        value:50,
                    },
                    {
                        id:0,
                        title: this.$t('options.sounds.blocked'),
                        soundName:'ACCESS_LOCKED',
                        ico:'fa fa-lock',
                        color:'#000',
                        value:50,
                    },
                    {
                        id:0,
                        title: this.$t('options.sounds.notification'),
                        soundName:'MESSAGE',
                        ico:'fa fa-info-circle',
                        color:'#000',
                        value:50,
                    },
                    {
                        id:0,
                        title: this.$t('options.sounds.order'),
                        soundName:'NewOrder',
                        ico:'fa fa-shopping-cart',
                        color:'#000',
                        value:50,
                    },
                ]
            }
        }
    },
    methods: {
        soundPlay(soundName, volume){
            const audio = new Audio(require('../../../../assets/sounds/'+soundName+'.mp3'));
            volume = volume / 100;
            audio.volume = volume;
            audio.play();
        },
    }
}
